import React, { Component } from "react";
import { tokenService } from "../common/services/token";
import { formatCurrency } from "../common/constants";
import { stakeService } from "../common/services/stake";
import { marketCap } from "../common/services/marketCap";
import dotool from "../../dotool";

const initialCardData = [
  {
      id:1,
      text1:'Circulating Supply (SCPT)',
      // text2:'1,000,000,000',
      text2: "N/A",
      text3:'Price',
      // text4:'$10,000,000',
      text4: "0",
  },
  {
      id:2,
      text1:'Circulating Supply (SPAY)',
      text2:'5,000,000,000',
      text3:'Price',
      // text4:'$2,500,000',
      text4: "N/A",
  },
  {
      id:3,
      text1:'Lightning Staked (SCPT)',
      text2:'0',
      text3:'Block Time',
      text4:'6 Sec',
  },
  {
      id:4,
      text1:'Validators',
      text2:'0',
      text3:'Total Nodes',
      text4:'0',
  },
]
export default class Chaindata extends Component {
  
  constructor(props){
    super(props);
    this.state={
      totalSCPTValue: 0,
      totalSPAYValue: 0,
      currentPage: 1,
      // backendAddress: this.props.route.backendAddress,
      loading: false,
      cardData: initialCardData,
      totalLightningNode: 0,
      totalValidatorNode: 0,
      totalValidators: 0,
      totalLightning: 0,
      totalEdge: 0,
    };
   
  }

  componentDidMount() {

    const { currentPage } = this.state;
    this.fetchData(currentPage);
    this.getTotalLightningNode();
    this.getTotalValidatorNode();
    this.getStakes();
    this.getMarketData();
  }
  
  fetchData(currentPage) {
    this.setState({ loading: true });
    tokenService
      .getCirculatingSuppyData()
      .then((res) => {
        const totalSCPTValue = res.data.totalScptWeiValue;
        const totalSPAYValue = res.data.totalSpayWeiValue;

        this.setState((prevState) => ({
          totalSCPTValue,
          totalSPAYValue,
          cardData: prevState.cardData.map((card) => {
            if (card.id === 1) {
              // return { ...card};
               return { ...card, text2: formatCurrency(totalSCPTValue )};
            }
            if (card.id === 2) {
              //return { ...card};
               return { ...card, text2: formatCurrency(totalSPAYValue) };
            }
            return card;
          }),
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  getTotalLightningNode() {
    stakeService
      .getTotalLightningNode()
      .then((res) => {
        const totalLightningNode = res.data.lightningNode;

        this.setState((prevState) => ({
          totalLightningNode,
          cardData: prevState.cardData.map((card) => {
            if (card.id === 3) {
             
              return { ...card, text2: formatCurrency(totalLightningNode) };
            }
            return card;
          }),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTotalValidatorNode() {
    stakeService
      .getTotalValidatorNode()
      .then((res) => {
        const totalValidatorNode =  res.data.validatorNode;

        this.setState((prevState) => ({
          totalValidatorNode,
          cardData: prevState.cardData.map((card) => {
            if (card.id === 4) {
              return { ...card};
//               return { ...card, text2: formatCurrency(totalValidatorNode) };
            }
            return card;
          }),
        }));  
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getStakes() {
    stakeService.getAllStake().then((res) => {
      if (res && res.data && res.data.body && res.data.body.length) {
        const totalVCP = dotool.offset__total_validator_nodes + res.data.body.filter(
          (validator) => validator.type === "vcp"
        ).length;
        const totalGCP = dotool.offset__total_lightning_nodes + res.data.body.filter(
          (validator) => validator.type === "gcp"
        ).length;
        const totalEENP = res.data.body.filter(
          (validator) => validator.type === "eenp"
        ).length;
        // this.setState({
        //   totalValidators: totalVCP,
        //   totalLightning: totalGCP,
        //   totalEdge: totalEENP,
        // });
        this.setState((prevState) => ({
          
          cardData: prevState.cardData.map((card) => {
            if (card.id === 4) {
              
              return { ...card, text2: totalVCP , text4: totalVCP+totalGCP+totalEENP };
            }
            return card;
          }),
        }));
      }
    });
  }

  getMarketData() {
    marketCap
      .getMarketDataPrice()
      .then((res) => {
        const { market_data } = res.data;
        const Price = market_data.current_price.usd;
        const Scpt =  market_data.circulating_supply;
        // console.log("market-data-price", Price);
        this.setState((prevState) => ({
          Price,
          cardData: prevState.cardData.map((card) => {
            if (card.id === 1) {
              return { ...card, text2:`${formatCurrency(Scpt.toFixed(0))}`, text4: `$${Price.toFixed(6)}` };
            }
            return card;
          }),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { t } = this.props;
    const { cardData } = this.state;

    // const { backendAddress } = this.props.route;
    // console.log("values",totalSCPTValue, totalSPAYValue);


    return (
      <div className="chain-data-box">
        {cardData.map((item) => {
          return (
            <div key={item?.id} className="card">
              <div className="title-one">{item?.text1}</div>
              <div className="title-two">{item?.text2}</div>

              <div className="title-one">{item?.text3}</div>
              <div className="title-two">{item?.text4}</div>
            </div>
          );
        })}
      </div>
    );
  }
}
