export const dotool = {
   ss_network: "mainnet",
   ss_version: "3.0",
   bridge_eth__URL: "https://eth-node-mainnet.script.tv/rpc",
   chain_id: "mainnet",
   chain_id_num: "",
   be_explorer__URL: "https://backend-explorer-mainnet.script.tv/api",
   fe_wallet__URL: "https://wallet-mainnet.script.tv",
   be_wallet__URL: "https://backend-wallet-mainnet.script.tv/api",
   fe_main__URL: "https://script.tv",
   fe_token_URL: "https://token.script.tv",
   partners_endpoint__URL: "https___partners_script_tv",
   fe_status_URL: "https://status.script.tv",
   fe_presale_URL: "https://presale.script.tv",
   fe_download_URL: "https://download.script.tv",
   nodemonitor_endpoint__URL: "https___nodemonitor_script_tv",
   whitepaper_endpoint__URL: "https://documentation.script.tv",
   market_api_URL: "https://api.coingecko.com/api",
   offset__total_lightning_nodes: 276,
   offset__total_validator_nodes: 16,
   system_version: "v4",
   google_tag__URL: "",

}

export default dotool;

