import React, { Component } from "react";
import Footer from "common/components/Footer";
import { RemixService } from "./common/services/remix";
import { soljsonReleases } from "./common/constants";
import Navbar2 from "./common/components/Navbar2";
import dotool from "../dotool";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewDashboard: true,
      currentPath: '/'
    };
    this.changePageLayout = this.changePageLayout.bind(this);
    this.getTabName = this.getTabName.bind(this);
  }

  componentDidMount() {
    const google_tag__URL = dotool.google_tag__URL;
    if(google_tag__URL){
      const iframe = document.getElementById('customGoogleTagIframe');
      iframe.src = google_tag__URL;
    }
    this.changePageLayout();
    RemixService.getCompilerVersion()
      .then((res) => {
        if (res && res.status === 200) {
          window.soljsonReleases = res.data.releases;
        } else {
          window.soljsonReleases = soljsonReleases;
        }
      })
      .catch((err) => {
        window.soljsonReleases = soljsonReleases;
      });
  }

  changePageLayout() {
    const path = window.location.pathname;
    this.getTabName(path);
    // console.log(path);
    if (path === "/") {
      this.setState({ isNewDashboard: true });
    } else {
      this.setState({ isNewDashboard: false });
    }
  }

  getTabName(path) {
    switch (path) {
      case "/":
        this.setState({
          currentPath: 'Overview'
        })
        return 
      case "/stakes":
        this.setState({
          currentPath: 'Stakes'
        })
        return;
      case "/tokens":
          this.setState({
            currentPath: 'Tokens'
          })
          return;
      case "/txs": 
        this.setState({
          currentPath: 'Transactions'
        })
        return;
      case "/blocks":
        this.setState({
          currentPath: 'Blocks'
        })
        return;
      default:
        if(path.includes('/blocks/')) {
          this.setState({
            currentPath: 'Block Detail'
          })
        } else {
          this.setState({
            currentPath: ''
          })
        }
        return;
    }
  }

  render() {
    const {currentPath} = this.state;
    return (
      <>
        {/* {this.state.isNewDashboard ? ( */}
        {/* <div id="dashboard-layout" className="dashboard-layout">
          <div className="side-nav-wrapper">
            <Sidenav/>
          </div>
          <div id="dashboard-content">
            <Navbar toggleLayout={this.changePageLayout} />
            <Breadcrumb path={currentPath} />
            {this.props.children}
            <Footer />
          </div>
        </div> */}

         <div className="new-dashboard-layout">
            <Navbar2 toggleLayout={this.changePageLayout}/>
           
            {this.props.children}
          
         </div>
         <div className='footer-style-explore' >
          <Footer/>   
                
        </div>
         <div className="footer-part-2">
                <div id='contact'><a href="https://contact@script.tv" target="_blank" className="footer_link">contact@script.tv</a></div>
                <div>Copyright © 2024 scripttv</div>
          </div>   
         
         {/* <Explorer/> */}
   

        {/* // ) : (
        //   <div id="app-container" className="body-color">
        //     <Header />
        //     <div id="app-content">{this.props.children}</div>
        //     <Footer />
        //   </div>
        // )} */}
      </>
    );
  }
}
