export const dotool = {
    git_version: "3105f10185-dirty",
    git_branch: "prod/250329",
    system_version: "v4",
    system_copyright_line: "Copyright © 2025 Script Network",
    ss_name: "fe/explorer",
    ss_home: "/home/mm/prod/system/fe/explorer",
    ss_version: "3.0",
    ss_network: "mainnet",
    ss_instance: "genesis",
    ss_cache_dir: "/home/mm/prod/.dotool/cache/release/fe/explorer/mainnet/genesis",
    tgt_path: "",
    tgt_instance_identifier: "",
    ss_test__delay: "0",
   bridge_eth__URL: "https://eth-node-mainnet.script.tv/rpc",
   chain_id: "mainnet",
   chain_id_num: "",
   be_explorer__URL: "https://backend-explorer-mainnet.script.tv/api",
   fe_wallet__URL: "https://wallet-mainnet.script.tv",
   be_wallet__URL: "https://backend-wallet-mainnet.script.tv/api",
   fe_main__URL: "https://script.tv",
   fe_token_URL: "https://token.script.tv",
   partners_endpoint__URL: "https___partners_script_tv",
   fe_status_URL: "https://status.script.tv",
   fe_presale_URL: "https://presale.script.tv",
   fe_download_URL: "https://download.script.tv",
   nodemonitor_endpoint__URL: "https___nodemonitor_script_tv",
   whitepaper_endpoint__URL: "https://documentation.script.tv",
   market_api_URL: "https://api.coingecko.com/api",
   system_version: "v4",
   google_tag__URL: "",
   be_b2c__URL: "https://backend-b2c-mainnet.script.tv",
}
export default dotool;
